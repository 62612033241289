
.languages {
    .dropdown-menu {
        min-width: 5rem;

        .dropdown-item {
            text-align: left !important;

            &:first-child {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            &:last-child {
                padding-bottom: 20px;
            }
        }
    }

    .flag-icon {
        vertical-align: middle;
        margin-top: -8px;
        border-radius: 3px;
    }
}


