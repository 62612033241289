@font-face {
    font-family: 'lingapi';
    src: url('../fonts/lingapi.eot');
    src: url('../fonts/lingapi.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lingapi.woff') format('woff'),
    url('../fonts/lingapi.ttf') format('truetype'),
    url('../fonts/lingapi.svg#fontname') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSansMedium';
    src: url('../fonts/OpenSans-Medium.eot');
    src: url('../fonts/OpenSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Medium.woff2') format('woff2'),
    url('../fonts/OpenSans-Medium.woff') format('woff'),
    url('../fonts/OpenSans-Medium.svg#OpenSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSansRegular';
    src: url('../fonts/OpenSans-Regular.eot');
    src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Regular.woff2') format('woff2'),
    url('../fonts/OpenSans-Regular.woff') format('woff'),
    url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSansBold';
    src: url('../fonts/OpenSans-Bold.eot');
    src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Bold.woff2') format('woff2'),
    url('../fonts/OpenSans-Bold.woff') format('woff'),
    url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

$font-bold: 'OpenSansBold';
$font-medium: 'OpenSansMedium';
$font-regular: 'OpenSansRegular';

@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-vue/dist/bootstrap-vue.min.css';

@import "~flag-icon-css/css/flag-icon.css";
@import "~toastr";

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}


* {
    font-family: $font-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: $color2;
    &:hover {
        text-decoration: none;
    }
}

html {
    height: 100%;
}

body {
    min-height: 100%;
    background-color: $white;
    font-size: 14px;
    margin: 0;

    #app {
        background-color: $grey-light;
        min-height: 100vh;
    }

    &.authorized {
        background-color: $grey-light !important;
    }

    .spacer {
        display: block;
        height: 10px;
    }

    [data-route=login], [data-route=register], [data-route=password-reset] {
        background-color: #FFF !important;
    }

    .b-skeleton-animate-wave {
        &::after {
            @media (prefers-reduced-motion: reduce) {
                background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.4), transparent) !important;
                animation: b-skeleton-animate-wave 1.75s linear infinite !important;
            }
        }
    }

    .b-table {
        tbody {
            tr {
                td {
                    padding-left: 0px !important;
                }
            }
        }
    }

    div[data-route=write], div[data-route=sworn] {
        .select2-container .select2-search--inline .select2-search__field {
            margin-top: auto;
        }
    }

    .select2-container--default {
        .select2-selection--single .select2-selection__rendered {
            padding-top: 6px;
        }

        .select2-selection--single {
            height: 40px !important;

            .select2-selection__arrow {
                height: 36px !important;
            }

        }

        #select2-source_lang-results {
            li:first-child {
                display: none !important;
            }
        }

        .select2-selection--multiple {
            min-height: 40px !important;

            li {
                height: 27px !important;
                padding-top: 6px !important;
                padding-right: 7px !important;
            }

            ul.select2-selection__rendered {
                padding-top: 3px;
            }

            textarea.select2-search__field {
                font-family: $font-regular !important;
                position: absolute;
                top: 6px;
            }

            ::placeholder {
                color: #000;
                opacity: 1;
            }

            .select2-selection__choice {
                background-color: #005aff !important;
                border: none !important;

                &, .select2-selection__choice__remove {
                    color: #FFF !important;
                }
            }

            .select2-selection__choice__remove {
                border: none !important;
                left: 5px;
                top: 6px;

                &:hover, &:focus {
                    background-color: #005aff !important;
                }
            }
        }
    }

    footer {
        position: absolute;
        bottom: 0;
        height: auto;
        padding: 15px 0;
        background-color: $color1;
        width: 100%;

        .content {
            display: flex;
            padding: 0 25px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .copyrights {
                font-size: 13px;
                color: #FFF;
            }
        }
    }

    .summary {
        .card-body {
            ul {
                margin-bottom: 9px;
            }
        }
    }

    header {
        background-color: #FFF;
    }

    .vertical-center {
        min-height: 100%;
        min-height: 100vh;
        display: flex;
        align-items: center;
    }

    .no-padding {
        padding: 0px !important;
    }

    .table thead th {
        border: none;
    }

    h1, h2, h3, h4, .button {
        font-family: $font-bold;
    }

    label {
        font-family: $font-medium;
        font-weight: normal;
        margin: 0;
    }

    .clearfix {
        &:after {
            clear: both;
            content: "";
            display: block;
        }
    }


    .lingy-checkbox {
        width: 100%;
        position: relative;
        box-shadow: none !important;

        input {
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            margin: 0;
        }

        &-info {
            color: #000;
            font-size: 16px;
            line-height: 1.4;
            padding-left: 30px;

            a {
                color: $color2;
            }
        }

        .help-block {
            padding-top: 15px;
        }
    }
}

#order-rating-box {
    .stars {
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            li {
                font-size: 24px;
                margin-right: 10px;
                cursor: pointer;
                &.active {
                    color: $color2;
                }
            }
        }
    }
}

.button {
    padding: 19px 32px;
    text-transform: uppercase;
    display: inline-block;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    outline: none;
    line-height: 1;
    background-image: none;
    border: 2px;
    user-select: none;
    text-decoration: none;
    transition: all .2s ease-in-out;

    &.disabled {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        opacity: .65;
    }
}

a.disabled {
    pointer-events: none;
}

.button--primary {
    background-color: $color2;
    color: $white;
    border-radius: 28px;
    border: 2px solid $color2;

    &:hover {
        background-color: $white;
        color: $color2;
        text-decoration: none;
    }

    &:focus {
        background-color: $color2;
        color: $white;
    }
}

.button--secondary {
    background-color: transparent;
    color: $color2;
    border-radius: 28px;
    border: 2px solid $color2;
    box-sizing: content-box;
    padding: 19px 30px;

    &:hover {
        background-color: $color2;
        color: $white;
    }

    &:focus {
        color: $color2;
        background-color: transparent;
    }
}

.button--order {
    padding: 19px 30px;
    background-color: $orange;
    border-color: $orange;
}

.button--settings {
    padding: 10px 32px;
}

.package-option-headline {
    display: block;
    height: 21px;
}
.package-option-description {
    padding: 0 5px;
}

a.button--order:hover{
    color: $orange;
}


.top-navigation {
    @media screen and (min-width: 1256px) {
        .navbar {
            .navbar-nav {
                > li {
                    padding: 0 20px;

                    > a {
                        padding: 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 990px) and (min-width: 767px) {
        .navbar-nav {
            > li {
                > a {
                    font-size: 13px !important;
                }
            }
        }
    }

    .nav-item a .bottom-bar {
        width: 100%;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: none;
    }

    .navbar-nav .nav-item a {
        position: relative;
    }


    .navbar {
        background-color: $white;
        min-height: 72px;
        padding: 0px;

        .navbar-brand {
            min-height: 72px;

            > img {
                height: 50px;
            }
        }

        .navbar-nav {
            > li {
                &:hover, &.active {
                    > a {
                        color: $color2;
                        background: none;
                    }
                }

                > a {
                    font-family: $font-bold;
                    color: $grey;
                    font-weight: normal;
                    height: auto;
                    font-size: 14px;
                    text-transform: uppercase;
                    overflow: hidden;
                }

                .dropdown-menu {
                    a {
                        font-family: $font-bold;
                        color: $grey;
                        text-align: center;
                        text-transform: uppercase;
                        font-size: 14px;
                    }
                }
            }
        }

        &.dark {
            background-color: $color1;
        }
    }

    .wrapper {
        &.wrapper-content {
            padding-top: 40px;
        }
    }
    [data-route="write"],[data-route="package"],[data-route="sworn"],[data-route="proofreading"]{
        button[type=submit]{
            background-color: $orange;
            border-color: $orange;
            color: #fff;
            &:hover{
                background-color: #fff;
                color: $orange;
            }
            &:focus{
                outline: none;
            }
        }
        .form-group {
            label {
                padding-bottom: 5px;
            }
        }
        hr {
            margin-bottom: 0;
        }
    }

    .slide-fade-enter-active {
        transition: all 1s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }

    [data-route=top-up-summary], [data-route=order-summary-order-hash], [data-route=order-summary-cart-hash] {
        .custom-control-label {
            padding: 4px 17px
        }
    }
    [data-route=top-up] {
        h2 {
            font-size: 21px;
        }
    }

    [data-route=order-summary-order-hash], [data-route=order-summary-cart-hash] {
        .billing-address {
            .form-group {
                margin-bottom: 10px !important;

                label {
                    padding: 16px 20px !important;
                }
            }
        }
    }
}

[data-route=order-summary-cart-hash] {
    #order-summary-payment-methods {
        .payment-method {
            display: inline-block;
            width: 33.3%;
            padding-right: 15px;
            &.active {
                >div {
                    border: 2px solid $color1;
                }
            }
            >div {
                border: 2px solid $grey-light;
                cursor: pointer;
                height: 150px;
            }
        }
    }
}

.card {
    @include border-radius(6px);

    .card-header {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background-color: $color1;
        color: $white;
        font-size: 17px;
        font-family: $font-bold;
        padding-left: 2.25rem;

        &.icon {
            padding-top: 9px;
            padding-bottom: 9px;

            a {
                float: left;
            }

            span {
                padding-top: 5px;
                padding-left: 10px;
            }
        }

        &:not(.icon) {
            span {
                padding-top: 2px;
            }

        }

        span {
            font-family: $font-bold;
            display: flex;
        }

        div {
            font-family: $font-bold;
        }
    }

    .card-body {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        padding-left: 2.25rem;
        padding-right: 2.25rem;
    }
}

#summary-price-gross-field-without-coupon, #summary-price-net-field-without-coupon {
    text-decoration: line-through;
}
#summary-price-gross-field-coupon, #summary-price-net-field-coupon {
    color: $orange;
}


.form-group {
    margin-bottom: 0;


    label {
        width: 100%;
        color: $grey;
        padding: 16px 20px;
        text-align: left;
        font-size: 13px;

        &.error {
            color: $error;
            margin: 0;
        }
    }

    .button {
        font-size: 13px;
    }

    &.error {
        label {
            color: $error;
        }
        input[type="text"] {
            border-color: $error;
        }
    }
}


.table {
    border-bottom: 1px solid #e7eaec;

    > thead {
        > tr {
            > th {
                color: $grey;
                font-family: $font-medium;
                font-weight: normal;
            }
        }
    }
}


a.dropdown-item:hover {
    background-color: transparent;
    color: #005aff !important;
}

.order-form {
    #content_from {
        height: 140px;
        line-height: 22px;
        font-size: 12px;
        font-weight: normal;
    }

    textarea::placeholder {
        font-size: 14px;
        color: #999;
    }

    #dropzone-dnd-area {
        display: none;

        .area {
            position: absolute;
            top: 0;
            z-index: 9999;
            width: 100%;
            .d-block {
                width: 100%;
            }
        }

        .text {
            border: 2px dashed #6c757d;
            background-color: #FFF;
            color: #000;
        }
    }

    .dropzone-dnd-backdrop {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: 0.3;
        z-index: 999
    }

    .dropzone-previews input {
        font-size: small;
    }

    .input-group.dz-error {
        .input-group-text {
            cursor: pointer;
            background-color: #dc3545;
            border: 1px solid #dc3545;
            color: #FFF;
        }
    }

    .has-error .select2-selection {
        border-color: rgb(185, 74, 72) !important;
    }

    #add_file {
        font-size: 14px;
    }

    &.disabled {
        button[type=submit] {
            display: none;
        }

        .hide {
            display: block !important;
        }

        .form-group[readonly] {
            .select2-selection {
                background-color: #e9ecef;
            }
        }
    }

    .dz-complete {
        .fa-spinner {
            &.fa-spin {
                animation: none !important;
            }

            &:before {
                content: "\F1F8" !important;
            }
        }
    }

    .text-black {
        color: #000000;
    }
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 100;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 23px;
}

h3 {
    font-size: 16px;
}

h4 {
    font-size: 14px;
}

h5 {
    font-size: 12px;
}

h6 {
    font-size: 10px;
}

h3,
h4,
h5 {
    font-weight: 600;
}

.table > thead > tr > th {
    padding-left: 0px;
}

hr {
    margin-top: 3px;
}

.pagination {
    font-size: 12px !important;
}

.invoice {
    .row.dates {
        margin-top: 45px;
    }
    .invoice_num {
        color: $color2;
    }
    .table-responsive {
        overflow-x: auto !important;
    }
    .table > tbody > tr > td, .invoice .table > tfoot > tr > td {
        border-top: none !important;
        padding-left: 0px;
    }
    .table {
        border-bottom: none !important;
    }
}

.order-details {
    color: #555;

    hr {
        margin-top: 4px !important;
    }

    h3 {
        text-transform: uppercase;
    }

    .text-muted {
        color: #a5a8b8 !important;
    }
}

.card-body {
    h3 {
        color: $color1;
        font-size: 14px;
        text-align: center;
        font-weight: 300;
        display: block;
        margin: 15px auto;
    }

    .border {
        border: 2px solid $color1 !important;
        border-radius: 15px;
        padding: 15px;

        &:hover {
            border: 4px solid $color1 !important;
            box-sizing: border-box;
            padding: 13px;
        }
    }

    .custom-padding {
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 15px;
    }
}
form{
    legend{
        text-align: left;
    }
}

[data-route=invoice] {
    .invoice_num {
        text-align: left;
    }
}

#tidio-chat {
    iframe {
        @media screen and (min-width: 900px) {
            bottom: 80px !important;
        }
    }
}

.submit-error-msg {
    color: $error;
}
#order-packages-cta-box {
    padding: 0 30px;
}

#cookies-box {
    background-color: $color1;
    padding: 12px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 101;

    .box {
        position: relative;
        a {
            color: #fff;
        }
        p {
            width: calc(100% - 100px);
            float: left;
            font-size: 13px;
            color: #fff;
            a {
                font-size: 13px;
                color: #fff;
                text-decoration: underline;
            }
        }

        button {
            float: right;
        }

        span {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: -10px;
            font-size: 12px;
            line-height: 1;
            color: #fff;
        }
    }
}

@media (max-width: 480px) {

    #cookies-box {
        .box {
            text-align: center;

            p {
                width: 100%;
                text-align: center;
                font-size: 11px;

                a {
                    font-size: 11px;
                }
            }

            button {
                float: none;
            }
        }
    }
}

#summary-order-paymentsbox {
    &>div {
        display: flex;
        flex-wrap: wrap;
        .field-item {
            display: block;
            overflow: hidden;
            width: 25%;
            padding-right: 12px;
            box-sizing: border-box;
            @media (max-width: 650px) {
                width: 33.3%;
            }
            .custom-control {
                padding-left: 0;
                label {
                    border-radius: 10px;
                    background-color: $grey-light3;
                    border: 2px solid $grey-light3;
                    cursor: pointer;
                    padding: 0;
                    overflow: hidden;
                    span {
                        display: block;
                        &.icon {
                            height: 150px;
                            padding-top: 35px;
                            background-color: $white;
                            img {
                                display: block;
                                margin: 0 auto;
                                max-width: 100%;
                            }
                        }
                        &.name {
                            color: $color1;
                            text-align: center;
                            padding: 12px 0;
                        }
                    }
                }
            }
            &.active {
                .custom-control {
                    label {
                        border: 2px solid $color1;
                        span.name {
                            background-color: $color1;
                            color: $white;
                            font-weight: 800;
                        }
                    }
                }
            }
        }
    }
}

body div.thulium-chat-wrapper {
    z-index: 200 !important;
}

#tm_formbox {
    width: 700px;
    margin: 0 auto;
    padding-top: 50px;
    .bannerbox {
        margin-bottom: 40px;
        img {
            display: block;
            max-width: 100%;
        }
    }
    .spinner-border {
        border-color: #ff6e64;
        border-right-color: transparent;
        margin-left: 15px;
    }
    fieldset {
        padding-bottom: 20px;
        margin-bottom: 20px;
        &.bb {
            border-bottom: 1px solid $grey-light2;
        }
        &.submit {
            padding-bottom: 0;
        }
        .title {
            font-size: 14px;
            color: $grey;
            margin-bottom: 10px;
        }
        .radio {
            display: flex;
            margin-bottom: 10px;
            label {
                margin-top: -2px;
                margin-left: 10px;
                font-size: 14px;
                cursor: pointer;
            }
        }
        .filename {
            font-size: 14px;
            color: $grey;
            margin-top: 10px;
        }
    }
    .filelink {
        a {
            color: #28a745;
        }
    }
    .errorinfo {
        color: $error;
    }
    .card {
        &.bottombox {
            margin-top: 50px;
            padding: 30px;
            * {
                color: $color1;
            }
            h3 {
                font-size: 14px;
            }
            ol {
                margin: 0;
            }
        }
    }
}

[data-route=mtdemo] {
   #content {
       margin-top: 0 !important;
   }
}

#order-summary {
    .card {
        border-radius: 20px;
        &.leftbox {
            .card-body {
                padding: 40px 30px;
                .upselling-box {
                    padding-bottom: 30px;
                    margin-bottom: 30px;
                    border-bottom: 1px solid $grey-light2;
                    .items {
                        display: flex;
                        justify-content: space-between;
                        @media (max-width: 650px) {
                            display: block;
                        }
                        .itembox {
                            width: calc(50% - 15px);
                            border-radius: 10px;
                            border: 1px solid $grey-light;
                            overflow: hidden;
                            @media (max-width: 650px) {
                                width: 100%;
                            }
                            &:nth-child(1) {
                                @media (max-width: 650px) {
                                    margin-bottom: 20px;
                                }
                            }
                            &.active {
                                border: 2px solid $color1;
                                .name {
                                    padding: 0 14px;
                                    line-height: 31px;
                                }
                                .text {
                                    padding: 20px 14px 19px 14px;
                                }
                            }
                            label {
                                cursor: pointer;
                            }
                            .name {
                                background-color: $grey-light;
                                color: $grey-dark;
                                line-height: 32px;
                                padding: 0 15px;
                                font-family: $font-bold;
                            }
                            .text {
                                padding: 20px 15px;
                                .price {
                                    font-family: $font-bold;
                                    font-size: 21px;
                                    line-height: 1;
                                    color: $grey-dark;
                                }
                                .txt {
                                    min-height: 51px;
                                    font-size: 14px;
                                    color: $grey-dark;
                                    line-height: 1.2;
                                    margin: 4px 0 12px 0;
                                }
                                .checkbox {
                                    display: flex;
                                    justify-content: flex-start;
                                    div {
                                        margin: -2px 0 0 10px;
                                    }
                                }
                            }
                        }
                    }
                }
                .payments-box {
                    padding-bottom: 30px;
                    margin-bottom: 30px;
                    border-bottom: 1px solid $grey-light2;
                }
                .data-formbox {
                    fieldset {
                        width: 100%;
                        margin-bottom: 12px;
                        label {
                            color: $grey;
                        }
                    }
                }
            }
        }
        &.rightbox {
            @media (max-width: 900px) {
                margin-top: 30px;
            }
            .card-body {
                padding: 0;
                .summary-itemsbox {
                    padding: 40px 30px;
                    .item {
                        display: flex;
                        margin-bottom: 8px;
                        &:last-child {
                            margin: 0;
                        }
                        .name {
                            width: 55%;
                        }
                        .val {
                            width: 45%;
                        }
                    }
                }
                .summary-pricingbox {
                    padding: 40px 30px;
                    background-color: $color2;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    .headline-box {
                        color: $white;
                    }
                    .item {
                        display: flex;
                        margin-bottom: 8px;
                        * {
                            font-size: 16px;
                            color: $white;
                        }
                        &.item-gross {
                            margin-bottom: 20px;
                            * {
                                font-family: $font-bold;
                            }
                        }
                        .name {
                            width: 55%;
                        }
                        .val {
                            width: 45%;
                        }
                    }
                    .coupon-field {
                        margin-bottom: 20px;
                        label {
                            color: $white;
                            margin-bottom: 8px;
                        }
                        input[type="text"] {
                            background: $white;
                            color: $grey-dark;
                            border: 2px solid $white;
                            border-radius: 0.25rem;
                            padding: 0 10px;
                            font-size: 1rem;
                            &.is-invalid {
                                border: 2px solid $error;
                            }
                        }
                    }
                    .btn-submitbox {
                        .btn-pay {
                            background-color: $green;
                            &:hover {
                                color: $white;
                            }
                        }
                        .errorsbox {
                            .error {
                                margin-top: 12px;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
        .headline-box {
            font-family: $font-bold;
            font-size: 18px;
            line-height: 1;
            margin-bottom: 20px;
            color: $color1;
        }
    }
}

.paymentbox-listbox {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 20px;
    background-color: #fff;
    padding: 20px;
    margin-top: 20px;
    .title {
        color: #a5a8b8;
        font-family: "OpenSansMedium";
        font-size: inherit;
        margin-bottom: 15px;
    }
    .items-colbox {
        display: flex;
        @media screen and (max-width: 500px) {
            display: block;
        }
        .item-colbox {
            &:first-child {
                width: 40%;
                @media screen and (max-width: 500px) {
                    width: 100%;
                    max-width: 230px;
                }
            }
            &:nth-child(2) {
                width: 60%;
                @media screen and (max-width: 500px) {
                    width: 100%;
                }
            }
            img {
                display: block;
                max-width: 100%;
            }
        }
    }
}

#paypobox-sidebar {
    background-color: $white;
    position: fixed;
    z-index: 4;
    right: 20px;
    bottom: 150px;
    padding: 60px 20px 40px 20px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    @media screen and (max-width: 1500px) {
        bottom: 120px;
        padding: 40px 12px 20px 12px;
    }
    @media screen and (max-width: 1400px) {
        right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    @media screen and (max-width: 1040px) {
        scale: 0.8;
        right: -15px;
    }
    @media screen and (max-width: 700px) {
        scale: 0.7;
        right: -20px;
    }
    @media screen and (max-width: 500px) {
        scale: 0.6;
        right: -27px;
    }

    &:before {
        content: "";
        display: block;
        background: url(../img/paypobox-bg1.png) no-repeat center;
        background-size: contain;
        width: 65px;
        height: 42px;
        position: absolute;
        top: 12px;
        right: 12px;
        @media screen and (max-width: 1500px) {
            width: 45px;
            height: 32px;
            top: 5px;
            right: 5px;
        }
    }

    &:after {
        content: "";
        display: block;
        background: url(../img/paypobox-bg2.png) no-repeat center;
        background-size: contain;
        width: 28px;
        height: 22px;
        position: absolute;
        bottom: 12px;
        left: 12px;
        @media screen and (max-width: 1500px) {
            width: 20px;
            height: 15px;
            bottom: 5px;
            left: 5px;
        }
    }

    .logo {
        background: url(../img/logo-paypobox.png) no-repeat center;
        background-size: cover;
        width: 120px;
        height: 32px;
        margin: 0 auto;
        @media screen and (max-width: 1500px) {
            width: 90px;
            height: 24px;
        }
    }

    .textbox {
        margin-top: 10px;
        @media screen and (max-width: 1500px) {
            margin-top: 6px;
        }

        div {
            &:nth-child(1) {
                font-family: 'OpenSansMedium';
                font-size: 14px;
                color: $color1;
                text-align: center;
            }

            &:nth-child(2) {
                font-family: 'OpenSansBold';
                font-size: 16px;
                line-height: 1;
                color: #26c7b5;
                text-align: center;
                @media screen and (max-width: 1500px) {
                    font-size: 14px;
                }
            }
        }
    }
}
#sworn-inpostbox {
    padding-top: 20px;
    height: 1px;
    opacity: 0;
    visibility: hidden;
    &.active {
        opacity: 1;
        visibility: visible;
        height: 500px;
    }
}

.tracking-link {
    color: $color1;
    text-decoration: underline;
}

.packages {

    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    ul {
        min-height: 170px;
        list-style-type: none;
    }

    .text-muted {
        color: #a5a8b8 !important;
    }

    .subheadline {
        min-height: 60px;
        font-size: 13px;
    }

    .variant {
        h3 {
            font-size: 18px;
        }

        &.active {
            box-shadow: .0px 0px 56px 1px #dfe0e6;

            label.button {
                cursor: not-allowed;
                background-color: #fff;
                color: $color2;
            }
        }
    }
}

.card-body {
    padding-bottom: 0;
    h3 {
        color: $color1;
        font-size: 14px;
        text-align: center;
        font-weight: 300;
        display: block;
        margin: 15px auto;
    }

    .border {
        border: 2px solid $color1 !important;
        border-radius: 15px;
        padding: 15px;

        &:hover {
            border: 4px solid $color1 !important;
            box-sizing: border-box;
            padding: 13px;
        }
    }

    .custom-padding {
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 15px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
        animation: 2s infinite linear normal b-icon-animation-spin !important;
        -webkit-animation: 2s infinite linear normal b-icon-animation-spin !important;
    }
}

@media (max-width: 750px) {
    ul {
        &.navbar-nav {
            li {
                a {
                    &.nav-link {
                        text-align: right;
                    }
                }
            }
        }
    }
}

header {
    .dark {
        display: none;

        &.login, &.register, &.password-reset, &.password-forgot {
            display: block !important;
        }

        ul {
            list-style-type: none;
        }

        @media (max-width: 750px) {
            #languages-nav-dropdown {
                a.nav-link {
                    padding-right: 0px;
                }
            }
        }

        .dropdown-toggle::after {
            color: #FFF;
        }
    }

    .container {
        &.login, &.register, &.password-reset, &.password-forgot {
            display: none !important;
        }
    }

}

nav {
    a {
        &.logout {
            cursor: pointer;
        }
    }

    .router-link-active {

        .bottom-bar {
            display: block !important;
        }
    }
}

